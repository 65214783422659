import React, { Component } from "react"
import PropTypes from "prop-types"
import { createRef } from "create-react-ref"
import { LazyGroup } from "react-lazy"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import { canUseWebP } from "../utils/browser"

export const boxFormats = {
  none: `box__none`,
  auto: `box__auto`,
  fullscreen: `box__fullscreen`,
  default: `box__default`,
  square: `box__square`,
  half: `box__half`,
  sixteenByNine: `box__sixteen_nine`,
  superScope: `box__super_scope`,
  postalCard: `box__postal_card`,
  portrait: `box__portrait`,
}
// if (typeof window !== `undefined`) {
//   const intersectionObserver = require(`intersection-observer`)
// }
class FigureBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasLoaded: false,
      loadingClassName: `is-loading`,
    }
    this.myRef = createRef()
    this.canUseWebP = canUseWebP()
  }

  // V3 doesn't support raw src tags
  backgroundStyle() {
    if (this.props.type === `background`) {
      const sourceFileURL = `${this.props.source.file.url}?fm=jpg&q=85&w=800`
      return {
        backgroundImage: `url("${
          this.canUseWebP && this.props.preferWebP && this.props.source.srcWebp
            ? this.props.source.srcWebp 
            : this.props.source.src || sourceFileURL
        }")`,
        backgroundSize: this.props.backgroundSize,
        backgroundPosition: this.props.backgroundPosition,
        backgroundRepeat: `no-repeat`,
        height: `100%`,
      }
    }
  }

  imageBackground() {
    if (this.props.type === `background`) {
      if(this.props.source.file){
        return <div title={this.props.alt} style={this.backgroundStyle()} />
      }
      else{
        const image = getImage(this.props.source)
        const bgImage = convertToBgImage(image)
        return (
          <BackgroundImage
            Tag="div"
            className={`gbImage`}
            style={{height: `100%`}}
            {...bgImage}
            preserveStackingContext
            keepStatic
          >
          </BackgroundImage>
        )
      }
    }
  }
  

  imageInline() {
    if (this.props.type === `inline`) {
      return (
        <GatsbyImage image={getImage(this.props.source)} alt={this.props.alt} />
        // <img
        //   alt={this.props.alt}
        //   src={
        //     this.canUseWebP &&
        //     this.props.preferWebP &&
        //     this.props.source.srcWebp
        //       ? this.props.source.srcWebp
        //       : this.props.source.src
        //   }
        //   srcSet={
        //     this.canUseWebP &&
        //     this.props.preferWebP &&
        //     this.props.source.srcSetWebp
        //       ? this.props.source.srcSetWebp
        //       : this.props.source.srcSet
        //   }
        //   className="img-fluid"
        // />
      )
    }
  }

  contentHasLoaded() {
    this.setState({
      hasLoaded: true,
      loadingClassName: `has-loaded`,
    })
  }

  placeHolderStyle() {
    // find ref node
    let node = this.myRef.current

    // calculate min-height when image has not loaded
    // this prevent the scroll from shifting before lazy-loading the asset
    if (
      node &&
      this.state.hasLoaded === false 
    ) {
      let width = parseInt(getComputedStyle(node)[`width`])
      let ratio = this.props.source.width / this.props.source.height
      return {
        height: width / ratio,
      }
    } else if (!node && this.state.hasLoaded === false) {
      return {
        minHeight: `50vh`,
      }
    }
  }

  render() {
    const TagName = this.props.tagName
    return (
      <TagName
        ref={this.myRef}
        className={`
          ${this.props.format}
          ${this.props.type}
          ${this.props.className}
        `}
      >
        <LazyGroup
          threshold={0.75}
          onLoad={() => this.contentHasLoaded()}
          cushion={`100% 0% 200% 0%`}
          className={`box__content`}
        >
          {this.imageBackground()}
          {this.imageInline()}
        </LazyGroup>
        {this.props.caption && (
          <figcation>
            <p>{this.props.caption}</p>
          </figcation>
        )}
      </TagName>
    )
  }
}

FigureBox.defaultProps = {
  format: boxFormats.default,
  type: `background`,
  tagName: `picture`,
  backgroundSize: `cover`,
  backgroundPosition: `top center`,
  preferWebP: true,
  caption: null,
  alt: null,
  className: null,
}

FigureBox.propTypes = {
  type: PropTypes.oneOf([`background`, `inline`]),
  source: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
}

export default FigureBox